<template>
  <q-toolbar :style="loginPageStyle">
    <slot name="left" />
    <div class="q-mr-xl q-ml-sm text-h6">
      <span>{{ $t("_RSFAP_SHARED_HEADER_BRAND_FIRST_LETTER") }}</span>
      <span>{{ $t("_RSFAP_SHARED_HEADER_BRAND_SECOND_PART") }}</span>
    </div>
    <template v-if="isLoggedIn">
      <app-button :to="{ name: routeNames.mainModules }" accent-color="header-accent" flat>
        {{ $t("_RSFAP_SHARED_HEADER_MODULES") }}
      </app-button>
      <app-button :to="{ name: routeNames.mainHelp }" accent-color="header-accent" flat>
        {{ $t("_RSFAP_SHARED_HEADER_HELP") }}
      </app-button>
      <app-button :to="{ name: routeNames.mainDocumentation }" accent-color="header-accent" flat>
        {{ $t("_RSFAP_SHARED_HEADER_DOCUMENTATION") }}
      </app-button>
    </template>
    <q-space />
    <div>{{ globalStore.globalConfig.appVersion }}</div>
    <div v-if="isAdmin" class="row items-center gap-1 q-mx-md">
      <app-button icon="settings" round flat accent-color="header-accent" :to="{ name: routeNames.mainModules, params: { mode: 'settings' } }" />
      <app-button icon="visibility" round flat accent-color="header-accent" :to="{ name: routeNames.mainSupervision }" />
      <app-button v-if="showStats" icon="bar_chart" round flat accent-color="header-accent" :to="{ name: routeNames.mainStatDashboard }" />
    </div>
    <app-button :loading="changingLanguage" flat>
      <div class="row items-center gap-2">
        <div>{{ currentLanguage }}</div>
        <app-country-flag :code="currentLanguage" />
      </div>
      <q-menu dark>
        <app-list>
          <q-item v-for="lang in supportedLanguages" :key="lang" clickable class="justify-center">
            <app-button :disable="changingLanguage" @click="translationsStore.setLanguage(lang)">
              <div class="row items-center gap-2">
                <div class="text-bold">{{ lang }}</div>
                <app-country-flag :code="lang" />
              </div>
            </app-button>
          </q-item>
        </app-list>
      </q-menu>
    </app-button>

    <app-button v-if="isLoggedIn" :label="displayName" icon-right="expand_more" flat>
      <q-menu dark>
        <app-list>
          <q-item v-if="userInfo.origin === 'USERS' || userInfo.origin === 'USERS_CNTCT'" clickable>
            <app-button :to="{ name: routeNames.mainAccountEdit, params: { mode: userInfo.id } }" :label="$t('_RSFAP_SHARED_HEADER_ACCOUNT')" />
          </q-item>
          <q-item clickable class="justify-center">
            <app-button :label="$t('_RSFAP_SHARED_HEADER_LOGOUT')" @click="sessionStore.logOut" />
          </q-item>
        </app-list>
      </q-menu>
    </app-button>
  </q-toolbar>
</template>

<script setup>
import { computed } from "vue"
import { storeToRefs } from "pinia"

import { useSessionStore } from "@/stores/session.js"
import { useGlobalStore } from "@/stores/global.js"
import { useTranslationsStore } from "@/stores/translations.js"
import { useModuleDataStore } from "@/stores/moduleData.js"

import { routeNames } from "@/constants/routes.js"

import AppCountryFlag from "@/components/commons/AppCountryFlag.vue"

const sessionStore = useSessionStore()
const translationsStore = useTranslationsStore()
const globalStore = useGlobalStore()

const { changingLanguage, currentLanguage } = storeToRefs(translationsStore)
const { moduleConfig } = storeToRefs(useModuleDataStore())

const showStats = computed(() => globalStore.globalConfig.modules.comsis && globalStore.globalSettings.enabledModules.includes("comsis"))

const loginPageStyle = computed(() => {
  return {
    backgroundColor: globalStore.globalSettings.loginPageTopBarBackgroundColor,
    color: `${globalStore.globalSettings.loginPageTopBarColor} !important`
  }
})

const supportedLanguages = computed(() => globalStore.globalSettings.enabledLanguages.filter((lang) => moduleConfig.value.supportedLanguages.includes(lang)))

const { isAdmin, isLoggedIn, userInfo, displayName } = storeToRefs(sessionStore)

const init = () => {
  const style = document.createElement("style")
  style.innerHTML = `.text-header-accent { color: ${globalStore.globalSettings.loginPageTopBarAccentColor} }`
  document.getElementsByTagName("head")[0].appendChild(style)
}

init()
</script>
