<template>
  <q-icon v-if="show" size="sm" name="info" color="primary">
    <!-- eslint-disable -->
    <q-tooltip class="bg-primary" v-html="value" />
  </q-icon>
</template>

<script setup>
import { computed } from "vue"

import { useGlobalStore } from "@/stores/global.js"
import { useModuleDataStore } from "@/stores/moduleData.js"

import i18n from "@/plugins/i18n.js"

import DOMPurify from "dompurify"

const props = defineProps({
  identifier: String,
  translationKey: String
})

const show = computed(() => {
  const globalStore = useGlobalStore()
  const moduleStore = useModuleDataStore()

  return (
    globalStore.globalConfig.settings.informationButtons &&
    moduleStore.moduleSettings.enabledInformationButtons.includes(props.identifier || props.translationKey)
  )
})

const value = computed(() => DOMPurify.sanitize(i18n.global.t(props.translationKey || props.identifier)))
</script>
