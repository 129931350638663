<template>
  <div>
    <app-input v-model="modelDisplay" :label="title" :readonly="!displayKeyOnly" :required="required" @blur="onModelDisplayBlur">
      <template #after>
        <app-button icon="filter_list" @click="modalVisible = true" :disable="disable" dense />
        <app-info-button v-if="infoButtonId || infoButtonKey" :identifier="infoButtonId" :translation-key="infoButtonKey" />
      </template>
      <template v-if="modelValue.length && !disable" #append>
        <app-button icon="close" @click="clear" dense flat size="sm" />
      </template>
    </app-input>
    <app-dialog v-if="modalVisible" min-width="600px" @ok="saveAndExit" @cancel="modalVisible = false">
      <template #title>
        {{ title }}
      </template>
      <template #content>
        <div class="column q-gutter-md">
          <div class="row q-gutter-sm">
            <div class="col-grow">
              <app-input v-model="search" :label="$t('_RSFAP_SHARED_FILTERS_SEARCH')" />
            </div>
            <app-button @click="search = null" :label="$t('_RSFAP_SHARED_CLEAR_DATA')" />
          </div>
          <app-table
            :rows="filteredItems"
            :columns="[
              { name: 'formatted', label: '' },
              { name: 'selected', label: $t('_RSFAP_SHARED_SELECTED') }
            ]"
            height="330px"
            row-key="Key"
          >
            <template #body-cell-selected="props">
              <q-td :props="props">
                <app-checkbox v-model="props.row.selected" />
              </q-td>
            </template>
            <template #header-cell-selected="props">
              <q-th :props="props" class="text-center" auto-width>
                <app-checkbox v-model="allItemsSelected" dark />
              </q-th>
            </template>
          </app-table>
        </div>
      </template>
    </app-dialog>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue"
import { storeToRefs } from "pinia"

import { useDictionariesStore } from "@/stores/dictionaries"
import { useTranslationsStore } from "@/stores/translations.js"

import { useSelection } from "@/composables/useSelection"

const props = defineProps({
  dictionaryName: String,
  additionalItems: {
    type: Array,
    default: () => []
  },
  modelValue: Array,
  required: Boolean,
  disable: Boolean,
  sortByKey: Boolean,
  sortByValue: Boolean,
  title: String,
  showValueOnly: Boolean,
  displayKeyOnly: Boolean,
  listAsValues: Boolean,
  whiteList: Array,
  infoButtonId: String,
  infoButtonKey: String
})

const emits = defineEmits(["update:modelValue"])

const dictionariesStore = useDictionariesStore()

const modalVisible = ref(false)
const modelDisplay = ref("")
const search = ref("")

const { changingLanguage } = storeToRefs(useTranslationsStore())

const items = computed(() => {
  let items = props.additionalItems ? [...props.additionalItems] : []
  const dictionary = dictionariesStore.getDictionary(props.dictionaryName)

  if (props.whiteList) {
    items = dictionary.filter((item) => props.whiteList.includes(item.Key))
  } else {
    items = items.concat(dictionary)
  }

  if (props.sortByKey) {
    items.sort((a, b) => a.Key.localeCompare(b.Key))
  }
  if (props.sortByValue) {
    items.sort((a, b) => a.Value.localeCompare(b.Value))
  }

  return items.map((item) => {
    item.formatted = props.showKeyOnly ? item.Key : props.showValueOnly ? item.Value : `${item.Key} - ${item.Value}`
    item.selected = props.modelValue.includes(item.Key)
    return item
  })
})

const { allSelected: allItemsSelected } = useSelection(items)

const filteredItems = computed(() => {
  if (!search.value) {
    return items.value
  }
  return items.value.filter((item) => item.formatted.toLowerCase().includes(search.value.toLowerCase()))
})

const onModelDisplayBlur = () => {
  const parsedKeysInput = modelDisplay.value.split(",").map((key) => key.trim())
  const newModel = parsedKeysInput.filter((key) => findKey(key))

  emits("update:modelValue", newModel)
}

const prepareDisplay = () => {
  const valuesArray = props.modelValue.map((key) => {
    if (props.displayKeyOnly) {
      return key
    }
    const additionalItem = props.additionalItems.find((item) => item.Key === key)
    return additionalItem ? additionalItem.Value : dictionariesStore.getValueForKey(props.dictionaryName, key)
  })

  modelDisplay.value = valuesArray.join()
}

const findKey = (keyInput) => {
  if (props.additionalItems && props.additionalItems.some((item) => item.Key === keyInput)) {
    return keyInput
  }
  return dictionariesStore.isKeyInDictionary(props.dictionaryName, keyInput) ? keyInput : null
}

const saveAndExit = () => {
  const selectedKeys = items.value.filter((item) => item.selected).map((item) => item.Key)

  emits("update:modelValue", selectedKeys)
  modalVisible.value = false
}

const clear = () => {
  emits("update:modelValue", [])
}

watch(() => props.modelValue, prepareDisplay, { immediate: true })
watch(() => changingLanguage.value, prepareDisplay)
</script>

<style lang="scss" scoped>
//Prevent overflowing of row container
.col-grow {
  flex-basis: 0;
}
</style>
