<template>
  <q-select
    ref="select"
    dense
    outlined
    :options="outputOptions"
    :rules="outputRules"
    emit-value
    map-options
    hide-bottom-space
    :loading="loading || $attrs.loading"
    :use-input="useInput"
    :hide-selected="useInput"
    :fill-input="useInput"
    popup-content-class="app-select-popup"
    @filter="filterFunction"
  >
    <template v-if="infoButtonId || infoButtonKey" #after>
      <app-info-button :identifier="infoButtonId" :translation-key="infoButtonKey" />
    </template>
    <template v-for="(slot, name) in $slots" #[name]="scope">
      <slot :name="name" v-bind="scope ?? {}" />
    </template>
  </q-select>
</template>

<script setup>
import { ref, watch } from "vue"
import { storeToRefs } from "pinia"

import { useDictionariesStore } from "@/stores/dictionaries.js"
import { useTranslationsStore } from "@/stores/translations.js"

import * as comparisonService from "@/services/comparisonService.js"

import { useValidators } from "@/composables/useValidators"

const validators = useValidators()
const dictionariesStore = useDictionariesStore()

const props = defineProps({
  options: {
    type: Array,
    default: () => []
  },
  dictionaryName: {
    type: String,
    default: null
  },
  dictionaryKeysAsNumbers: {
    type: Boolean,
    default: false
  },
  emptyOptionLabel: {
    type: String,
    default: null
  },
  useInput: {
    type: Boolean,
    default: false
  },
  filterHandler: Function,
  itemFormatter: Function,
  required: Boolean,
  rules: Array,
  showValueOnly: Boolean,
  showKeyOnly: Boolean,
  sortByKey: Boolean,
  sortByValue: Boolean,
  infoButtonId: String,
  infoButtonKey: String
})

const select = ref(null)
const loading = ref(false)

defineExpose({ validate: () => select.value.validate() })

const outputOptions = ref([])
const allOptions = ref([])
const outputRules = ref(props.rules || [])

const { changingLanguage } = storeToRefs(useTranslationsStore())

if (props.required) {
  outputRules.value.push(validators.required)
}

const getLabelForDictionaryItem = (item) => {
  if (props.showValueOnly) {
    return dictionariesStore.getValueForKey(props.dictionaryName, item.Key)
  } else if (props.showKeyOnly) {
    return item.Key
  } else {
    return dictionariesStore.getFormattedDictionaryValueFromDictionary(props.dictionaryName, item.Key)
  }
}

const setOutputOptions = () => {
  let items = []
  if (props.dictionaryName) {
    const dict = dictionariesStore.getDictionary(props.dictionaryName)

    if (props.sortByKey) {
      dict.sort((a, b) => comparisonService.compare(a, b, "Key"))
    }

    if (props.sortByValue) {
      dict.sort((a, b) => comparisonService.compare(a, b, "Value"))
    }

    items = dict.map((item) => ({
      label: getLabelForDictionaryItem(item),
      value: props.dictionaryKeysAsNumbers ? parseInt(item.Key, 10) : item.Key
    }))
  } else if (props.options.length && typeof props.options[0] !== "object") {
    items = props.options.map((option) => ({
      label: option,
      value: option
    }))
  } else {
    items = props.options.map((option) => ({
      label: option.label,
      value: option.value
    }))
  }

  if (props.emptyOptionLabel) {
    items.unshift({
      label: props.emptyOptionLabel,
      value: null
    })
  }

  outputOptions.value = items
  allOptions.value = items.slice()
}

const filterFunction = async (val, update) => {
  if (!props.useInput) {
    update()
    return
  }

  if (props.filterHandler) {
    try {
      loading.value = true
      const { items } = await props.filterHandler(val)
      loading.value = false
      update(() => {
        outputOptions.value = items.map((item) => (props.itemFormatter ? props.itemFormatter(item) : item))
      })
    } catch (error) {
      console.error(error)
    }
  } else {
    update(() => {
      outputOptions.value = allOptions.value.filter((v) => !val || v.label.toLowerCase().indexOf(val.toLowerCase()) > -1)
    })
  }
}

watch(() => props.options, setOutputOptions, { immediate: true })

watch(
  () => changingLanguage.value,
  (newValue) => {
    if (!newValue) {
      setOutputOptions()
    }
  }
)
</script>

<style lang="scss">
.app-select-popup {
  .q-item--dark.q-item--active {
    color: $accent;
  }
}
</style>
