import axios from "axios"

export const getGlobalConfiguration = () => axios.get("/api/main/appSettings/getGlobalConfiguration")

export const getGlobalSettings = () => axios.get("/api/main/appSettings/getGlobalSettings")

export const getMainModuleSettings = () => axios.get(`/api/main/appSettings/getModuleSettings`)

export const getHelpPageSettings = () => axios.get("/api/main/appSettings/getHelpPageSettings")

export const getMapSettings = () => axios.get("/api/main/appSettings/getMapSettings")

export const getAvailableImageMapSettingsForUser = () => axios.get("/api/main/appSettings/getAvailableImageMapSettingsForUser")

export const setGlobalSettings = (settings) => axios.post("/api/main/appSettings/setGlobalSettings", settings)

export const getRegistrationMailTemplates = () => axios.get("/api/main/appSettings/getRegistrationMailTemplates")

export const radioSystemsNames = () => axios.get("/api/main/appSettings/radioSystemsNames")

export const setRegistrationMailTemplates = (settings) => axios.post("/api/main/appSettings/setRegistrationMailTemplates", settings)

export const getAvailableMaps = () => axios.get("/api/main/appSettings/getAvailableMaps")

export const getMapSettingsForAdmin = () => axios.get("/api/main/appSettings/getMapSettingsForAdmin")

export const setMapSettings = (settings) => axios.post("/api/main/appSettings/setMapSettings", settings)
